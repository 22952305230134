<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">ID：</span>
        <el-input v-model="search.id" placeholder="请输入ID" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">标题：</span>
        <el-input v-model="search.title" placeholder="请输入标题" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div>
      <div class="search-box">
        <span class="search-box-title">分类：</span>
        <el-select v-model="search.type_id" filterable placeholder="全部" size="medium" clearable>
          <el-option key="-1" label="全部" value="-1"></el-option>
          <el-option key="0" label="未分类" value="0"></el-option>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">是否发布：</span>
        <el-select v-model="search.is_release" filterable placeholder="全部" size="medium" clearable>
          <el-option v-for="item in releaseArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">是否免费</span>
        <el-select v-model="search.is_free" placeholder="全部" size="medium">
          <el-option label="全部" value="-1"></el-option>
          <el-option label="收费" value="0"></el-option>
          <el-option label="免费" value="1"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">时间：</span>
        <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
          value-format="YYYY-MM-DD"></el-date-picker>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
        <el-button type="primary" size="medium" @click="showFufei()">批量付费设置</el-button>
        <el-button type="primary" size="medium" @click="multiDel()">批量删除</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="视频标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="url" label="视频地址" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="watch_count" label="观看次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="good_count" label="点赞次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="is_release" label="是否发布" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <div :style="{color: scope.row.is_release == '1' ? '#33C933' : '#F33333' }" @click="useVideo(scope.row)">{{scope.row.is_release == 1 ? "已发布" : "未发布"}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="is_release" label="是否免费" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <div :style="{color: scope.row.is_fee == '1' ? '#33C933' : '#F33333' }">{{scope.row.is_fee == 1 ? "免费" : "付费"}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template #default="scope">
          <span class="operation" @click="getInEdition(scope.row,1)">添加精选</span>
          <span class="operation" @click="getInEdition(scope.row,0)">添加专题</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog :title="title" v-model="dialogAdd" width="800px" top="5vh" :close-on-click-modal="false" @close="closeDialog">
      <el-row>
        <el-col :span="6">视频标题：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.title" placeholder="视频标题" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">视频分类：</el-col>
        <el-col :span="18">
          <el-select v-model="dialogArr.type_id" placeholder="无分类" size="medium" multiple @change="changeType">
            <el-option label="未分类" :value="0"></el-option>
            <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="title !== '添加视频'">
        <el-col :span="6">观看次数：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.watch_count" placeholder="观看次数" size="small" type="number"></el-input>
        </el-col>
      </el-row>
      <el-row v-if="title !== '添加视频'">
        <el-col :span="6">点赞次数：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.good_count" placeholder="点赞次数" size="small" type="number"></el-input>
        </el-col>
      </el-row>
      <el-row v-if="title !== '添加视频'">
        <el-col :span="6">是否发布：</el-col>
        <el-col :span="18">
          <el-switch class="switch" :active-value="1" :inactive-value="2" v-model="dialogArr.is_release"></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">视频封面图：</el-col>
        <el-col :span="18">
          <el-upload class="avatar-uploader" action="#" accept="image/*" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
<!--            <img  :src="dialogArr.imgUrl" class="avatar" />-->
            <ximg :src="dialogArr.imgUrl" v-if="dialogArr.imgUrl" class="avatar" id="video_avatar" alt=""></ximg>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="note">支持扩展名:.png .jpg</div>
          </el-upload>
        </el-col>
      </el-row>
        <el-row  v-if="title !== '添加视频'">
          <el-col :span="6">付费设置：</el-col>
          <el-col :span="18">
            <el-form label-width="80px" aria-label="left">
              <el-form-item label="是否收费">
                <el-switch class="switch" :active-value=0 :inactive-value=1 v-model="dialogArr.is_fee"></el-switch>
              </el-form-item>
              <el-form-item label="购买价格"  v-show="dialogArr.is_fee == 0" >
                <el-input type="number" style="width:100px" min="0" v-model="dialogArr.buy_config.price" placeholder="请输入购买金额"></el-input>
              </el-form-item>
              <el-form-item label="试看时间"  v-show="dialogArr.is_fee == 0">
                <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" :picker-options="time_picker_options" v-model="dialogArr.buy_config.try_btime"></el-time-picker>-
                <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" :picker-options="time_picker_options" v-model="dialogArr.buy_config.try_etime"></el-time-picker>
              </el-form-item>
<!--              <el-form-item label="VIP"  v-show="dialogArr.is_fee == 0">-->
<!--                <el-select v-model="dialogArr.buy_config.free_vips" multiple="multiple" clearable="clearable">-->
<!--                  <el-option v-for="item of vipList" :key="item" :label="item.label" :value="item.value"></el-option>-->
<!--                </el-select><i>收费开启时,选中的vip免费观看</i>-->
<!--              </el-form-item>-->
            </el-form>
          </el-col>
        </el-row>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加视频'">确认修改</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 视频修改精选或者专题 -->
    <el-dialog :title="listTitle" v-model="dialoList" width="1180px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div style="padding-bottom:60px">
        <div style="width:700px;display:inline-block;">
          <el-table :data="type == 0 ? typeListArr : typeListArr1" :header-cell-style="{ background: '#F7F8FA' }" height="538">
            <el-table-column prop="id" label="ID" width="100px" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="200px" align="center">
              <template #default="scope">
                <span class="operation" v-if="!scope.row.checked" @click="addVideoList(scope.row)">添加</span>
                <span v-else style="color:#ccc">已添加</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="selecteds">
          <div class="selectedsHerder">
            <span>已选择视频（{{listArr.length}}）</span>
            <span class="selectedsEmpty" @click="emptyVideoList()">清空</span>
          </div>
          <div class="selectedsList">
            <el-table :data="listArr" :show-header="false" :header-cell-style="{ background: '#F7F8FA' }" height="488">
              <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                  <div>({{scope.row.id}}){{scope.row.title}}</div>
                </template>
              </el-table-column>
              <el-table-column width="70px" align="center">
                <template #default="scope">
                  <span class="operation" @click="delVideoList(scope.row)">移除</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addToEdition">确定修改</el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
        title="批量付费设置"
        v-model="dialogFufei"
        width="700px"
        top="10vh"
        :close-on-click-modal="false"
    >
      <el-row >
        <el-col :span="4">付费设置：</el-col>
        <el-col :span="20">
          <el-form label-width="80px" aria-label="left">
            <el-form-item label="是否收费">
              <el-switch class="switch" :active-value=0 :inactive-value=1 v-model="fufeiArr.is_fee"></el-switch>
            </el-form-item>
            <el-form-item label="购买价格" v-show="fufeiArr.is_fee == 0">
              <el-input type="number" style="width:100px" min="0" v-model="fufeiArr.price" placeholder="请输入购买金额"></el-input>
            </el-form-item>
            <el-form-item label="试看时间"  v-show="fufeiArr.is_fee == 0">
              <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" v-model="fufeiArr.try_btime"></el-time-picker>-
              <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini"  v-model="fufeiArr.try_etime"></el-time-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20" align="center">
          <el-button type="primary" @click="dialogFufei = false">取消</el-button>
          <el-button type="primary" @click="saveFufei" >保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
        title="部分视频付费设置失败"
        v-model="dialogFufeiTips"
        width="700px"
        top="10vh"
        :close-on-click-modal="false"
    >
      <el-row >
        <el-col :span="14">视频</el-col>
        <el-col :span="10">失败原因</el-col>
      </el-row>
      <el-row v-for="item in errList" :key="item">
        <el-col :span="14">{{item.title}}</el-col>
        <el-col :span="10">{{ item.errMsg }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="10"></el-col>
        <el-col :span="14">
          <el-button type="primary" @click="dialogFufeiTips = false">关闭</el-button>
        </el-col>
      </el-row>
    </el-dialog>


  </div>
</template>

<script>
import {
  getList,
  add,
  edit,
  del,
  use,
  getType,
  getInEdition,
  videosBest,
  videosEdition,
  addToEdition,
} from "@/api/video/list";
import { upload } from "@/api/common/common";
import { nextTick } from "vue";
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "videoList",
  components: {
    page,
  },
  data() {
    return {
      errList:[],
      dialogFufeiTips:false,
      pager: { total: 0, page: 1, rows: 10 },
      pages: { total: 0, page: 1, rows: 1000 },
      search: {
        //搜索
        id: "", //id
        title: "", //标题
        type_id: -1, //分类
        searchDate: [], //时间
        checked: 1, //是否精确搜索
        is_release: 0, //是否发布
        is_free:"-1",
      },
      tableData: [{}], //数据
      row: [], //选中的数据
      typeArr: [
        { label: "全部", value: -1 },
        { label: "未分类", value: 0 },
      ],
      releaseArr: [
        { label: "全部", value: 0 },
        { label: "已发布", value: 1 },
        { label: "未发布", value: 2 },
      ],
      dialogAdd: false, //添加弹窗
      title: "",
      dialogArr: {
        title: "", //标题
        type_id: [], //分类
        is_fee: 0, //是否免费
        url: "", //图片地址
        imgUrl: "", //图片完整地址
        is_release: 2, //是否发布
        watch_count: 0, //观看次数
        good_count: 0, //点赞次数
        buy_config:{
          price:0,// "购买金额"
          try_etime:"",// 时间区间选择
          try_btime:"",// 时间区间选择
          free_vips:"", // vip选择
        },
      }, //弹窗编辑数组
      time_picker_options:{
        // start:"00:00:00",
        // end:"01:10:10",
        // step: '00:02',
        selectableRange:'18:30:00 - 20:30:00',
    },
      vipList:[],
      dialoList: false, // 精选 专题弹窗
      type: 0, // 0专题 1精选
      typeListArr: [], //专题 数组
      typeListArr1: [], // 精选 数组
      listArr: [], // 已有精选 专题数据
      listTitle: "", // 精选 专题弹窗标题
      video: {}, //视频数据
      fufeiArr:{
        is_fee:0,
        price:6,// "购买金额"
        try_etime:"",// 时间区间选择
        try_btime:"",// 时间区间选择
        free_vips:"", // vip选择
      },
      dialogFufei: false,
      multipleSelection:[],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getViplist()
    this.getType();
    this.getList();
    this.videosEdition();
    this.videosBest();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    multiDel(){
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择要删除的视频!', type: 'error' });
        return;
      }
      this.$confirm("批量删除视频？此操作无法撤回,请谨慎操作", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        var ids = [];
        for(var i in this.multipleSelection){
          ids.push(this.multipleSelection[i].id)
        }
        this.$common.showLoading("批量删除视频")
        httpClient("multiDelVideo").post({
          "ids":ids.join(","),
        }).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.$message({ message: "删除成功", type: 'success' });
            this.getList()
          }else{
            this.$message({ message: res.msg, type: 'error' });
          }
        })
      })
    },
    saveFufei(){
      // 保存付费设置
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      var ids = [];
      for(var i in this.multipleSelection){
        ids.push(this.multipleSelection[i].id)
      }
      this.$common.showLoading("批量付费设置")
      httpClient("multiFufeiVideo").post({
        "ids":ids.join(","),
        "is_fee":this.fufeiArr.is_fee,
        "price":this.fufeiArr.price,
        "try_btime":this.getTime(this.fufeiArr.try_btime),
        "try_etime":this.getTime(this.fufeiArr.try_etime),
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.dialogFufei = false
          if(res.data.errList != undefined && res.data.errList.length > 0){
            this.errList = res.data.errList
            this.dialogFufeiTips = true
          }else{
            this.$message({ message: "设置成功", type: 'success' });
          }
          this.getList()
        }else{
          this.$common.hideLoading()
          this.$message({ message: res.msg, type: 'error' });
        }
      })
    },
    showFufei(){
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      this.fufeiArr.try_btime = this.getTime2(0)
      this.fufeiArr.try_etime = this.getTime2(120)
      this.dialogFufei = true;
    },
    getViplist(){
      // 读取vip列表
      var _this = this
      httpClient("getVipList").post().then((res)=>{
        console.log(res)
        var vipList = []
        for(const vip of res.data){
          vipList.push({
            label:vip.vip_name,
            value:vip.id+"",
          })
        }
        _this.vipList = vipList
      })
    },
    //获取视频分类
    getType() {
      getType().then((res) => {
        if (res.code == 0) {
          this.typeArr = res.data;
        }
      });
    },
    //获取管理员数据
    getList() {
      if (!this.search.type_id && this.search.type_id !== 0) {
        this.search.type_id = -1;
      }
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "id", val: this.search.id },
        { key: "title", val: this.search.title },
        { key: "type_id", val: this.search.type_id },
        {
          key: "begin_time",
          val:
            this.search.searchDate.length > 0
              ? this.search.searchDate[0] + " 00:00:00" || ""
              : "",
        },
        {
          key: "end_time",
          val:
            this.search.searchDate.length > 0
              ? this.search.searchDate[1] + " 23:59:59" || ""
              : "",
        },
        { key: "checked", val: this.search.checked || 0 },
        { key: "is_release", val: this.search.is_release || 0 },
        { key: "is_free", val: this.search.is_free },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加视频
    add() {
      add([
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id },
        { key: "switch", val: this.dialogArr.is_fee },
        { key: "url", val: this.dialogArr.url || "pic/default.png" },
        // { key: "vUrl", val: this.dialogArr.vUrl },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //把数据放入编辑中
    editor(row) {
      let typeId = row.type_id.split(",");
      let arr = [];
      for (let i = 0; i < typeId.length; i++) {
        arr.push(Number(typeId[i]));
      }
      var buy_config =  {
        price:0,
        try_btime:0,
        try_etime:0,
        free_vips:""
      }
      if (row.buy_config != ""){
        buy_config = JSON.parse(row.buy_config)
        if(buy_config.price === undefined){
          buy_config.price = 0
        }
        if(buy_config.try_btime === undefined){
          buy_config.try_btime = 0
        }
        if(buy_config.try_etime === undefined){
          buy_config.try_etime = 0
        }
        if(buy_config.free_vips === undefined){
          buy_config.free_vips = ""
        }
      }
      // if (buy_config.price === undefined || buy_config.price === 0){
      //   buy_config.price = 6
      // }
      // if (buy_config.try_etime === undefined || buy_config.try_etime === 0){
      //   buy_config.try_btime = 0
      //   buy_config.try_etime = 120
      // }
      this.row = row;
      this.title = `编辑视频(ID：${row.id})`;
      this.dialogArr = {
        title: row.title, //标题
        type_id: arr, //分类
        is_fee: row.is_fee, //是否免费
        url: row.banner_, //图片地址
        imgUrl: row.banner, //图片完整地址
        is_release: row.is_release == 0 ? 2 : 1, //是否发布
        watch_count: row.watch_count, //观看次数
        good_count: row.good_count, //点赞次数
        buy_config:{
          price:buy_config.price,
          try_btime:this.getTime2(buy_config.try_btime),
          try_etime:this.getTime2(buy_config.try_etime),
          free_vips:"",
        }
      };
      this.dialogAdd = true;
    },
    //编辑视频
    edit() {
      if (this.dialogArr.watch_count < 0 || this.dialogArr.good_count < 0) {
        return this.$message({
          message: "点赞次数和观看次数不能为负数",
          type: "error",
        });
      }
      this.$common.showLoading("编辑视频")
      edit([
        { key: "id", val: this.row.id },
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id.join(",") || 0 },
        { key: "switch", val: this.dialogArr.is_fee },
        { key: "url", val: this.dialogArr.url },
        { key: "is_release", val: this.dialogArr.is_release == 2 ? 0 : 1 },
        { key: "watch_count", val: this.dialogArr.watch_count },
        { key: "good_count", val: this.dialogArr.good_count },
        { key: "buy_config_price", val: this.dialogArr.buy_config.price},
        { key: "buy_config_btime", val: this.getTime(this.dialogArr.buy_config.try_btime)},
        { key: "buy_config_etime", val: this.getTime(this.dialogArr.buy_config.try_etime)},
        { key: "buy_config_vips", val: ""},
      ])
        .then((res) => {
          this.$common.hideLoading()
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          this.$common.hideLoading()
          console.log(err);
        });
    },
    getTime(t){
      if(t == "" || t == null){
        return 0
      }
      var rows = t.split(":")
      if (rows.length != 3){
        return 0
      }
      return parseInt(rows[0]) * 3600 + parseInt(rows[1]) * 60 + parseInt(rows[2])
    },
    getTime2(t){
      var h = Math.floor(t / 3600)
      var m = Math.floor((t - (h*3600)) / 60)
      var s = t - (h*3600+m*60)
      return h+":"+m+":"+s
    },
    // 发布视频
    useVideo(row) {
      this.row = row;
      use([{ key: "id", val: this.row.id }])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除视频
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除视频：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭弹窗后清空
    closeDialog() {
      this.dialogArr = {
        title: "", //标题
        type_id: "", //分类
        is_fee: 0, //是否免费
        is_release: 2, //是否发布
        url: "", //图片地址
        imgUrl: "", //图片完整地址
        // vUrl: "", //视频地址
        videoUrl: "",
        buy_config:{
          price:0,
          try_btime:0,
          try_etime:0,
          free_vips:"",
        }
      }; //清空添加修改弹窗数据
      this.row = []; //清空选中数据
      this.emptyVideoList();
    },
    //上传图片
    upload(param) {
      this.$common.showLoading("上传中请稍后!")
      this.createImg(param.file,(file)=>{
        upload({ file: file, name: "video",isencode:"true" })
          .then((res) => {
            this.$common.hideLoading()
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogArr.url = res.data.filename;
              this.dialogArr.imgUrl = res.data.path;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })

    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      this.dialogArr.imgUrl = URL.createObjectURL(file)
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
        return false
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false
      }
      return true
    },
    createImg(file,callback){
      var img = document.createElement("img")
      img.width = 375
      img.height = 211
      img.src = URL.createObjectURL(file)
      setTimeout(()=>{
        var canvas = document.createElement("canvas")
        canvas.width = 375
        canvas.height = 211
        canvas.getContext('2d').drawImage(img,0,0,canvas.width,canvas.height)
        callback(this.base64ToFile(canvas.toDataURL("image/png"),(new Date()).toTimeString() + ".png"))
      },200)
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    //获取专题数据
    videosEdition() {
      videosEdition([
        { key: "page", val: this.pages.page },
        { key: "row", val: this.pages.rows },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.typeListArr = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取精选数据
    videosBest() {
      videosBest([
        { key: "page", val: this.pages.page },
        { key: "row", val: this.pages.rows },
        { key: "type_id", val: -1 },
        { key: "status", val: -1 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.typeListArr1 = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取视频已有的专题或精选并且打开弹窗
    getInEdition(row, type) {
      this.type = type;
      this.video = row;
      this.$message({ message: "获取数据中~" });
      this.listTitle = `${type == 0 ? "专题" : "精选"}列表(视频ID：${row.id})`;
      getInEdition([
        { key: "id", val: row.id },
        { key: "type", val: type },
      ])
        .then((res) => {
          if (res.code == 0) {
            if (this.type == 0) {
              for (let i = 0; i < res.data.length; i++) {
                let is = this.typeListArr.indexOf(
                  this.typeListArr.filter((data) => data.id == res.data[i])[0]
                );
                if (is != -1) {
                  this.listArr.push(
                    this.typeListArr.filter((data) => data.id == res.data[i])[0]
                  );
                  this.typeListArr[
                    this.typeListArr.indexOf(
                      this.typeListArr.filter(
                        (data) => data.id == res.data[i]
                      )[0]
                    )
                  ].checked = true;
                }
              }
            } else {
              for (let i = 0; i < res.data.length; i++) {
                let is = this.typeListArr1.indexOf(
                  this.typeListArr1.filter((data) => data.id == res.data[i])[0]
                );
                if (is != -1) {
                  this.listArr.push(
                    this.typeListArr1.filter(
                      (data) => data.id == res.data[i]
                    )[0]
                  );
                  this.typeListArr1[
                    this.typeListArr1.indexOf(
                      this.typeListArr1.filter(
                        (data) => data.id == res.data[i]
                      )[0]
                    )
                  ].checked = true;
                }
              }
            }

            this.dialoList = true;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加已有专题或精选
    addVideoList(data) {
      if (this.type == 0) {
        let index = this.typeListArr.indexOf(data);
        this.listArr.push(data);
        this.typeListArr[index].checked = true;
      } else {
        let index = this.typeListArr1.indexOf(data);
        this.listArr.push(data);
        this.typeListArr1[index].checked = true;
      }
    },
    // 移除已有专题或精选
    delVideoList(data) {
      if (this.type == 0) {
        let index = this.listArr.indexOf(data);
        nextTick("checked", this.listArr[index]);
        let obj = this.listArr[index];
        this.listArr.splice(index, 1);
        this.typeListArr[this.typeListArr.indexOf(obj)].checked = false;
      } else {
        let index = this.listArr.indexOf(data);
        nextTick("checked", this.listArr[index]);
        let obj = this.listArr[index];
        this.listArr.splice(index, 1);
        this.typeListArr1[this.typeListArr1.indexOf(obj)].checked = false;
      }
    },
    // 清空已有专题或精选
    emptyVideoList() {
      if (this.type == 0) {
        for (let i = 0; i < this.listArr.length; ) {
          nextTick("checked", this.listArr[0]);
          let obj = this.listArr[0];
          this.listArr.splice(0, 1);
          this.typeListArr[this.typeListArr.indexOf(obj)].checked = false;
        }
      } else {
        for (let i = 0; i < this.listArr.length; ) {
          nextTick("checked", this.listArr[0]);
          let obj = this.listArr[0];
          this.listArr.splice(0, 1);
          this.typeListArr1[this.typeListArr1.indexOf(obj)].checked = false;
        }
      }
    },
    // 更新视频已有的专题或精选成功后关闭弹窗
    addToEdition() {
      let arr = [];
      this.listArr.forEach((index) => {
        arr.push(index.id);
      });
      addToEdition([
        { key: "id", val: this.video.id },
        { key: "type", val: this.type },
        { key: "edition_id", val: arr.join(",") || 0 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialoList = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑选中的数据
    changeType(arr) {
      console.log(arr);
      let i = arr.indexOf(0);
      if (i == 0) {
        this.dialogArr.type_id.splice(i, 1);
      } else if (i != -1) {
        return (this.dialogArr.type_id = [0]);
      }
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.note {
}
.el-row{margin-bottom: 5px;}
.pages {
  height: 30px;
  line-height: 30px;
  margin: 10px 0;
  float: right;
}
.selecteds {
  width: 400px;
  height: 536px;
  float: right;
  border: 1px solid #ccc;
}
.selectedsHerder {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background-color: #eee;
  padding: 0 20px;
}
.selectedsEmpty {
  float: right;
  color: #409eff;
  cursor: pointer;
}
.el-form-item{margin-bottom: 5px;}
</style>
