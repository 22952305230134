import { qspost } from '@/config/axios';

//获取视频列表
export const getList = data => qspost("request?", data, "videosList");

//获取视频分类
export const getType = data => qspost("request?",data,"videosType");

//新增视频
export const add = data => qspost("request?", data, "addVideosList");

//编辑视频
export const edit = data => qspost("request?",data, "updateVideosList");

//删除视频
export const del = data => qspost("request?",data, "deleteVideosList");

// 发布视频
export const use = data => qspost("request?",data, "useVideo");

//获取视频已存在的专题或精选列表
export const getInEdition = data => qspost("request?",data, "getInEdition");

//获取精选数据
export const videosBest = data => qspost("request?", data, "videosBest");

//获取专题数据
export const videosEdition = data => qspost("request?", data, "videosEdition");

//更新专题 或 精选列表
export const addToEdition = data => qspost("request?", data, "addToEdition");